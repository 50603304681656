import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'react-css-modules';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import styles from './styles.scss';
import { getRedGreen } from '../../data/codeRedGreen/actions';
import Header from "../../components/Header";
import ReportRange from "../../components/ReportRange";
import Card from "../../components/Card";
import Green from "./components/Green";
import Red from "./components/Red";
import Mood from "./components/Mood";
import ShiftRating from "./components/ShiftRating";
import OperationIssues from "./components/OperationIssues";


export const MoodIcons = {
	Happy: <FontAwesomeIcon icon={['fas', 'smile']} />,
	Okay: <FontAwesomeIcon icon={['fas', 'meh']} />,
	Sad: <FontAwesomeIcon icon={['fas', 'sad-tear']} />,
	Angry: <FontAwesomeIcon icon={['fas', 'angry']} />,
	Unwell: <FontAwesomeIcon icon={['fas', 'dizzy']} />
}

const CodeRedGreen = (props) => {
	const {
		getEntries,
		codeRedGreen,
		entries
	} = props;

	const initialValues = {
		startDate: moment().startOf('month').format('YYYY-MM-DD'),
		endDate: moment().endOf('month').format('YYYY-MM-DD'),
		servicePeriod: '',
		areaOfWork: ''
	};

	const [startMood, setStartMood] = useState(false);
	const [endMood, setEndMood] = useState(false);
	const [shiftRating, setShiftRating] = useState(0);
	const [operationIssues, setOperationIssues] = useState(false);

	const processRedGreen = (entries) => {
		const operationIssues = {};
		const startMood = {};
		const endMood = {};
		let shiftRating = 0;


		for (const entry of entries) {
			shiftRating += entry.acf.shift_rating ? parseInt(entry.acf.shift_rating) : 0;

			startMood[entry.acf.showed_up_for_shift_feeling] = startMood[entry.acf.showed_up_for_shift_feeling]
				? startMood[entry.acf.showed_up_for_shift_feeling] += 1 : 1;

			endMood[entry.acf.left_shift_feeling] = endMood[entry.acf.left_shift_feeling]
				? endMood[entry.acf.left_shift_feeling] += 1 : 1;


			const operationIssuesArray = entry.acf.operation_issue.split(', ');

			for (const issue of operationIssuesArray) {
				operationIssues[issue] = operationIssues[issue]
					? operationIssues[issue] += 1 : 1;
			}

		}

		shiftRating = shiftRating / entries.length;

		setStartMood(startMood);
		setEndMood(endMood);
		setShiftRating(shiftRating);
		setOperationIssues(operationIssues);
	}

	// Get Code Red Green Parameters on load
	// Default to today and all
	useEffect(() => {
		getEntries();
	}, []);

	// Watch for change in Code Reg / Green entries
	useEffect(() => {
		if (!codeRedGreen.isRequesting) {
			// Process all the newly loaded entries
			processRedGreen(codeRedGreen.entries);
		}
	}, [codeRedGreen.isRequesting])

	return (
		<Fragment>
			<Header pageTitle="Code Red / Green" />
			<ReportRange initialValues={initialValues} getEntries={getEntries}/>
			<div styleName="stats" className="row">
				<div className="col">
					<Card cardTitle="Mood" autoHeight={true}>
						<Mood startMood={startMood} endMood={endMood} />
					</Card>
				</div>
				<div className="col">
					<Card cardTitle="Shift Rating" autoHeight={true}>
						<ShiftRating shiftRating={shiftRating} />
					</Card>
				</div>
				<div className="col">
					<Card cardTitle="Operation Issues" autoHeight={true}>
						<OperationIssues operationIssues={operationIssues} />
					</Card>
				</div>
			</div>
			<div styleName="red-green" className="row">
				<div className="col">
					<Card cardTitle="Green">
						<Green items={entries} />
					</Card>
				</div>
				<div className="col">
					<Card cardTitle="Red">
						<Red items={entries} />
					</Card>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = state => {
	const { codeRedGreen } = state.data;

	return {
		codeRedGreen,
		entries: codeRedGreen.entries,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getEntries: (
			startDate,
			endDate,
			servicePeriod,
			areaOfWork
		) => dispatch(getRedGreen(startDate, endDate, servicePeriod, areaOfWork))
	}
}

CodeRedGreen.propTypes = {
	getEntries: PropTypes.func,
	codeRedGreen: PropTypes.object,
	entries: PropTypes.array
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CodeRedGreen, styles));
