import React from "react";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import '@babel/polyfill';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose} from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import reducers from './reducers';
import App from './App';
import SiteContext from './siteContext';

const history = createBrowserHistory();

const axiosInstance = axios.create({
	baseURL: 'wp-json/wp/v2'
});

const middlewares = [
	thunk.withExtraArgument(axiosInstance),
];

// Log redux store to console during development
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
	// eslint-disable-next-line global-require,no-undef
	const { createLogger } = require('redux-logger');

	const loggerMiddleware = createLogger();

	middlewares.push(loggerMiddleware);
}

// Redux dev tools extension
const composeEnhancers = typeof window === 'object'
	&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	}) : compose;

const storeEnhancer = composeEnhancers(
	applyMiddleware(...middlewares)
	// other store enhancers if any
);

const store = createStore(
	reducers,
	window.INITIAL_STATE,
	storeEnhancer
);

const rootElement = document.getElementById('root');
const basePath = rootElement.getAttribute('data-base-path');

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<ScrollToTop>
				<SiteContext.Provider value={{basePath}}>
					<App/>
				</SiteContext.Provider>
			</ScrollToTop>
		</Router>
	</Provider>,
	rootElement
);
