import axios from 'axios';

export default class API {
	constructor(api = false) {
		this.api = api;

		if (!api) {
			this.api = axios.create({});
		}
	}

	async fetchFromAPI(endpoint, taskId, task, method = 'get', data = {}, filename = '') {
		let receivedData = false;
		let errorResponse = false;

		try {
			const response = await this.api({
				method,
				url: endpoint,
				data,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				onUploadProgress: (progress) => {
					// console.log(progress);
				}
			});

			receivedData = response.data;

		} catch (error) {
			data = false;

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				// Dispatch Error
				const {status, statusText = '', data = false} = error.response;

				errorResponse = {
					status,
					detail: statusText,
					taskId,
					task,
					data
				};

			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				errorResponse = {
					status: error.request.status,
					detail: error.request.statusText,
					taskId,
					task,
					data
				}
			} else {
				// Something happened in setting up the request that triggered an Error
				errorResponse = {
					status: 500,
					detail: 'A technical error occured - please try again.',
					taskId,
					task,
					data
				};
			}
		}

		const response = {data: receivedData, errors: errorResponse};


		return response;
	}

}
