import API from "./api";
import moment from "moment";

export const REQUEST_RED_GREEN = 'REQUEST_RED_GREEN';
export const RECEIVE_RED_GREEN = 'RECEIVE_RED_GREEN';


const requesting = () => {
	return {
		type: REQUEST_RED_GREEN
	};
}

const receive = (data) => {
	return {
		type: RECEIVE_RED_GREEN,
		entries: data
	};
}

const getRedGreen = (
	startDate= moment().startOf('month').format('YYYY-MM-DD'),
	endDate= moment().endOf('month').format('YYYY-MM-DD'),
	servicePeriod='',
	areaOfWork='') => {
	return async (dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(requesting());

		const json = await dataAPI.getRedGreen(startDate, endDate, servicePeriod, areaOfWork);

		dispatch(receive(json.data));
	}
};

export { getRedGreen };
