import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import withStyles from 'react-css-modules';
import moment from "moment";

import styles from './styles.scss';


const Green = props => {
	const {
		items
	} = props;

	const renderItems = () => {
		return items.map(item => {
			const itemDate = moment(item.acf.day, 'YYYY-MM-DD');
			return (
				<div styleName="green" key={item.id}>
					<div styleName="green__header">
						{itemDate.format('ddd, D MMM')} <span>{item.acf.service_period}</span>
					</div>
					<div styleName="green__message">
						{item.acf.green}
					</div>
					<div styleName="green__footer">
						{item.acf.name} <span>{item.acf.area_of_work} - {item.acf.position_for_shift}</span>
					</div>
				</div>
			);
		})
	};

	return (
		<Fragment>
			{renderItems()}
		</Fragment>
	);
};

Green.propTypes = {
	items: PropTypes.array
}

export default withStyles(Green, styles);