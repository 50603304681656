import React from "react";
import withStyles from 'react-css-modules';
import PropTypes from 'prop-types';

import styles from './styles.scss';


const Card = (props) => {
	const { children, cardTitle, autoHeight } = props;
	const className = autoHeight ? 'auto-height' : '';

	return (
		<div styleName="card" className={className}>
			{cardTitle &&
				<div styleName="card__header">
					<h3 styleName="card__title">{cardTitle}</h3>
				</div>
			}
			<div styleName="card__body">
				{children}
			</div>
		</div>
	)
};

Card.propTypes = {
	children: PropTypes.element,
	cardTitle: PropTypes.string,
	autoHeight: PropTypes.bool
}

export default withStyles(Card, styles);