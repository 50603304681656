import React from "react";
import withStyles from 'react-css-modules';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from './styles.scss';

const Sidebar = () => {
	return (
		<div className="col-xs-12 col-md-3" styleName="sidebar">
			<ul styleName="sidebar__items">
				<li styleName="sidebar__item">
					<Link to="/">
						<div styleName="sidebar__icon">
							<FontAwesomeIcon icon={['fas', 'traffic-light']} />
						</div>
						Code Red / Green
					</Link>
				</li>
				<li styleName="sidebar__item">
					<Link to="/">
						<div styleName="sidebar__icon">
							<FontAwesomeIcon icon={['fas', 'smile']} />
						</div>
						NPS
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default withStyles(Sidebar, styles);
