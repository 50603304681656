import React, { useState, useEffect } from "react";
import withStyles from 'react-css-modules';
import PropTypes from 'prop-types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withFormik, Form, Field } from 'formik';
import moment from "moment";

import styles from './styles.scss';
import Card from "../Card";

const ReportRange = props => {
	const {
		values,
		setFieldValue
	} = props;

	// Parse initial date range
	const startDate = values.startDate ? moment(values.startDate).toDate() : new Date();
	const endDate = values.endDate ? moment(values.endDate).toDate() : new Date();

	const [dateRange, setDateRange] = useState([startDate, endDate]);

	useEffect(() => {
		const startDate = moment(dateRange[0]).format('YYYY-MM-DD');
		const endDate = moment(dateRange[1]).format('YYYY-MM-DD');

		setFieldValue('startDate', startDate);
		setFieldValue('endDate', endDate);

	}, [dateRange]);

	return (
		<Card cardTitle="Date Range">
			<Form styleName="report-range">
				<div styleName="report-range__col">
					<div className="form-group">
						<DateRangePicker
							onChange={setDateRange}
							value={dateRange}
							format="dd/M/y"
							className="date-range-picker"
							calendarIcon={<FontAwesomeIcon icon={['far', 'calendar-alt']} />}
						/>
						<small className="text-muted">
							Select date range
						</small>
					</div>
					<div className="form-group">
						<Field
							as="select"
							styleName="report-range__field"
							className="custom-select"
							name="servicePeriod"
						>
							<option value="">Please Select</option>
							<option value="all">All</option>
							<option value="breakfast">Breakfast</option>
							<option value="lunch">Lunch</option>
							<option value="dinner">Dinner</option>
						</Field>
						<small className="text-muted">
							Select service period
						</small>
					</div>
					<div className="form-group">
						<Field
							as="select"
							styleName="report-range__field"
							className="custom-select"
							name="areaOfWork"
						>
							<option value="">Please Select</option>
							<option value="all">All</option>
							<option value="front of house">Front of House</option>
							<option value="back of house">Back of House</option>
							<option value="admin">Admin</option>
						</Field>
						<small className="text-muted">
							Select area
						</small>
					</div>
				</div>
				<div styleName="report-range__col">
					<button type="submit" styleName="report-range__update">
						Update
					</button>
				</div>
			</Form>
		</Card>
	)
}

ReportRange.propTypes = {
	values: PropTypes.object,
	setFieldValue: PropTypes.func
};


const EnhancedReportRange = withFormik({
	mapPropsToValues: (props) => (props.initialValues),

	handleSubmit: async (values, { setSubmitting, props }) => {
		const { getEntries } = props;

		await getEntries(
			values.startDate,
			values.endDate,
			values.servicePeriod,
			values.areaOfWork
		);

		setSubmitting(false);
	},
})(withStyles(ReportRange, styles));


EnhancedReportRange.propTypes = {
	initialValues: PropTypes.object.isRequired
};

EnhancedReportRange.defaultProps = {
	startDate: '',
	endDate: '',
	servicePeriod: '',
	areaOfWork: ''
};

export default EnhancedReportRange;

