import { library } from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/free-brands-svg-icons';

import {
	faTrafficLight,
	faSmile,
	faSadTear,
	faAngry,
	faMeh,
	faDizzy
} from '@fortawesome/pro-solid-svg-icons';

import { faSpinner, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';

// import {} from '@fortawesome/pro-light-svg-icons';


export default library.add(
	faSpinner,
	faTrafficLight,
	faSmile,
	faCalendarAlt,
	faSadTear,
	faAngry,
	faMeh,
	faDizzy
);
