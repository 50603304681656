import React, { Fragment } from "react";
import withStyles from 'react-css-modules';
import PropTypes from 'prop-types';
import {ResponsiveContainer, PieChart, Pie, Label} from 'recharts'

import styles from './styles.scss';


const ShiftRating = props => {
	const {
		shiftRating
	} = props;

	const shiftRatingPercent = shiftRating * 10;

	let color = '#53d769';

	if (shiftRatingPercent < 66) {
		color = '#fd9426';
	}

	if (shiftRatingPercent < 33) {
		color = '#fc3158';
	}

	const data = [
		{ id: 1, name: 'Average Shift Rating', value: shiftRatingPercent, fill: color },
		{ id: 2, name: 'Average Shift Rating', value: 100 - shiftRatingPercent, fill: '#f9f9f9' },
	];

	return (
		<div styleName="shift-rating">
			<ResponsiveContainer>
				<PieChart>
					<Pie
						data={data}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						outerRadius={90}
						innerRadius={70}
						startAngle={90}
						endAngle={-270}
						stroke="none"
					>
						<Label className="rating" value={shiftRating.toString()} position="center"/>
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
}

ShiftRating.propTypes = {
	shiftRating: PropTypes.number
};

ShiftRating.defaultProps = {
	shiftRating: 0
};

export default withStyles(ShiftRating, styles);