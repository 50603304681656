import React from "react";
import PropTypes from 'prop-types';
import withStyles from 'react-css-modules';

import styles from './styles.scss';


const Header = (props) => {
	const { pageTitle } = props;

	return (
		<div styleName="header">
			<h1 styleName="header__title">{pageTitle}</h1>
		</div>
	)
};

Header.propTypes = {
	pageTitle: PropTypes.string.isRequired
}

export default withStyles(Header, styles);