import React, { Fragment } from "react";
import withStyles from 'react-css-modules';
import PropTypes from 'prop-types';
import {ResponsiveContainer, PieChart, Pie, Label} from 'recharts'

import styles from './styles.scss';


const OperationIssues = props => {
	const {
		operationIssues
	} = props;

	const RADIAN = Math.PI / 180;

	const colors = {
		'Facilities and Equipment': '#003f5c',
		'Training': '#2f4b7c',
		'Marketing issue': '#665191',
		'Communication issue': '#a05195',
		'Didn\'t meet guest expectations': '#d45087',
		'Out of stock': '#f95d6a',
		'Not delivered in time frame': '#ff7c43',
		'Weather/Environment': '#ffa600',

	}

	const sorted = [];
	let total = 0;

	for (const issue in operationIssues) {
		total += operationIssues[issue];
		sorted.push({ name: issue, value: operationIssues[issue], fill: colors[issue] });
	}

	sorted.sort((a, b) => {
		return b.value - a.value;
	});

	const data = sorted.map(issue => {
		return (
			{
				...issue,
				value: (issue.value / total) * 100
			}
		)
	});

	const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name } ) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x  = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy  + radius * Math.sin(-midAngle * RADIAN);

		return (
			<Fragment>
				<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					{`${(percent * 100).toFixed(0)}%`}
				</text>
			</Fragment>
		)
	}

	const renderKey = () => {
		return (
			data.map(item => {
				return (
					<li styleName="operation-issues__legend" key={item.name}>
						<span style={{background: item.fill}}></span>
						{item.name}
					</li>
				);
			})
		);
	}

	return (
		<div styleName="operation-issues">
			<ResponsiveContainer>
				<PieChart>
					<Pie
						data={data}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						outerRadius={90}
						startAngle={90}
						endAngle={-270}
						label={renderLabel}
						labelLine={false}
					>
					</Pie>
				</PieChart>
			</ResponsiveContainer>
			<ul>
				{ renderKey() }
			</ul>
		</div>
	);
}

OperationIssues.propTypes = {
	operationIssues: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool
	])
};

OperationIssues.defaultProps = {
	operationIssues: {}
};

export default withStyles(OperationIssues, styles);