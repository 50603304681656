import { REQUEST_RED_GREEN, RECEIVE_RED_GREEN} from './actions';

const initialState = {
	isRequesting: false,
	entries: []
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_RED_GREEN:
			return Object.assign({}, state, {
				isRequesting: true,
			});
		case RECEIVE_RED_GREEN:
			return Object.assign({}, state, {
				isRequesting: false,
				entries: action.entries
			});
		default:
			return state
	}
};
