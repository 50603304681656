import baseApi from '../api';
import moment from 'moment';

export default class redGreenAPI extends baseApi {
	async getRedGreen(startDate, endDate, servicePeriod, areaOfWork) {

		const queryStringParameters = {
			service_period: servicePeriod === 'all' ? '' : servicePeriod,
			area_of_work: areaOfWork === 'all' ? '' : areaOfWork,
			start_date: startDate,
			end_date: endDate
		};

		const queryString = Object.keys(queryStringParameters).map(key => {
			return `${key}=${queryStringParameters[key]}`;
		}).join('&');

		const endpoint = queryString ? `/red-green-entries?${queryString}` : '/red-green-entries';

		const response = await this.fetchFromAPI(
			endpoint,
			'get-red-green',
			'Get Red Green',
			'get',
		);

		return response;
	}
}
