import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import withStyles from 'react-css-modules';

import './FontAwesome';
import styles from './app-styles.scss';
import Sidebar from "./components/Sidebar";
import CodeRedGreen from "./pages/CodeRedGreen";

const App = () => {

	return (
		<div styleName="app" className="container-fluid">
			<div className="row">
				<Sidebar />
				<div className="col pt-2 pb-5">
					<Switch>
						<Route path="/" exact>
							<CodeRedGreen />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
}

export default withRouter(withStyles(App, styles));

