import React, {Fragment} from "react";
import PropTypes from "prop-types";
import withStyles from "react-css-modules";

import styles from './styles.scss';
import { MoodIcons } from "../../index";


const Mood = props => {
	const {
		startMood,
		endMood
	} = props;

	const renderMoods = moods => {
		const sorted = [];
		let total = 0;

		for (const mood in moods) {
			total += moods[mood];
			sorted.push([mood, moods[mood]]);
		}

		sorted.sort((a, b) => {
			return b[1] - a[1];
		});

		return sorted.map( (mood, index) => {
			return (
				<li styleName="moods__item" key={`mood-${index}`}>
					{MoodIcons[mood[0]]}
				</li>
			)
		})
	};

	return (
		<Fragment>
			<ul styleName="moods">
				{renderMoods(startMood)}
			</ul>
			<h5 styleName="mood-label">Showed up to shift</h5>
			<ul styleName="moods">
				{renderMoods(endMood)}
			</ul>
			<h5 styleName="mood-label">Left shift</h5>
		</Fragment>
	);
}

Mood.propTypes = {
	startMood: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool
	]),
	endMood: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool
	]),
};

export default withStyles(Mood, styles);